import React, { Component } from 'react'
import { translate } from 'react-i18next';
import { Link } from 'gatsby';

declare let window: any;

interface IProps {
  /**
   * `shortname` tells the Disqus service your forum's shortname,
   * which is the unique identifier for your website as registered
   * on Disqus. If undefined , the Disqus embed will not load.
   */
  shortname: string,

  /**
   * `identifier` tells the Disqus service how to identify the
   * current page. When the Disqus embed is loaded, the identifier
   * is used to look up the correct thread. If disqus_identifier
   * is undefined, the page's URL will be used. The URL can be
   * unreliable, such as when renaming an article slug or changing
   * domains, so we recommend using your own unique way of
   * identifying a thread.
   */
  identifier?: string,

  /**
   * `url` tells the Disqus service the URL of the current page.
   * If undefined, Disqus will take the global.location.href.
   * This URL is used to look up or create a thread if disqus_identifier
   * is undefined. In addition, this URL is always saved when a thread is
   * being created so that Disqus knows what page a thread belongs to.
   */
  url?: string,

  prefix?: string

  t?: Function,
  i18n?: any,
  tReady?: any,
}

class DisqusCommentCount extends Component<IProps> {
  static defaultProps = {
    prefix: '',
  };

  componentDidMount() {
    if (typeof window !== 'undefined' && window.document) {
      const disqusScript = document.querySelector(`script[src$="disqus.com/count.js"]`);
      if (disqusScript && typeof disqusScript.parentNode === 'object') {
        disqusScript.parentNode.removeChild(disqusScript);
        if (typeof window.DISQUSWIDGETS !== 'undefined') {
          window.DISQUSWIDGETS = undefined;
        }
      }
      const script = document.createElement('script');
      script.src = `//mijnpresta.disqus.com/count.js`;
      script.async = true;
      script.onload = () => {
        window.DISQUSWIDGETS.forum = this.props.shortname;
        window.DISQUSWIDGETS.getCount({reset: true});
      };
      document.body.appendChild(script);
    }
  }

  componentDidUpdate() {
    if (typeof window !== 'undefined' && window.document && typeof window.DISQUSWIDGETS !== 'undefined') {
      window.DISQUSWIDGETS.forum = this.props.shortname;
      window.DISQUSWIDGETS.getCount({reset: true});
    }
  }

  render () {
    const { identifier, url } = this.props;
    let { prefix } = this.props;
    const attrs = {};
    if (typeof identifier !== 'undefined') {
      attrs['data-disqus-identifier'] = identifier;
    }
    if (typeof url !== 'undefined') {
      attrs['data-disqus-url'] = url;
    }
    if (typeof prefix === 'undefined') {
      prefix = window.location.href;
    }

    return (<Link to={`${prefix}#disqus_thread`} className="disqus-comment-count" {...attrs}/>)
  }
}

export default translate()(DisqusCommentCount);
