import React, { Component } from 'react';
import { Link } from 'gatsby';
import { translate } from 'react-i18next';

interface IProps {
  breadcrumbs?: BreadCrumb[],
  i18n?: any,
}

class BreadCrumbs extends Component<IProps> {
  render() {
    const { i18n } = this.props;
    return (
      <div className="breadcrumb-container">
        <ul className="breadcrumb">
          {this.props.breadcrumbs.map((item, index) => {
            if (item.href){
              return <li key={index}><Link to={`/${i18n.language}/${item.href[i18n.language]}`}>{item.text}</Link></li>;
            }
            return <li key={index}>{item.text}</li>;
          })}
        </ul>
      </div>
    )
  }
}

export default translate()(BreadCrumbs);
