import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { translate, TranslationFunction } from 'react-i18next';
import {
  FacebookShareButton,
  TwitterShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,

  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  EmailIcon,
} from 'react-share';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCalendar, faTag, faComment } from '@fortawesome/free-solid-svg-icons';

import Disqus from '../components/Disqus';
import DisqusCommentCount from '../components/DisqusCommentCount';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { formatBlogDate } from '../misc/tools';
import BreadCrumbs from '../components/BreadCrumbs';

import '../assets/sass/main.scss';
import '../components/i18n';
import { i18n } from 'i18next';
import { IBlogNode } from '../layouts/BlogLayout';

interface IProps {
  t: TranslationFunction,
  i18n: i18n,
  pageContext: {
    post: IBlogNode,
  },
  location: {
    pathname: string,
  },
}

function Template(props: IProps) {
  const { t, i18n, pageContext: { post }, location: { pathname: path } } = props;
  const sharePath = `https://www.mijnpresta.nl${path}`;
  return (
    <div className="main-wrapper">
      <Header/>
      <BreadCrumbs breadcrumbs={[
        {
          text: 'Home',
          href: {
            nl: '',
            en: '',
          }
        },
        {
          text: 'Blog',
          href: {
            nl: 'blog/',
            en: 'blog/',
          }
        },
        {
          text: post.frontmatter.title,
        },
      ]}/>
      <section id="main" className="wrapper">
        <div className="inner">
          <div className="blog-post-container">
            <Helmet
              title={`Mijn Presta | Blog | ${post.frontmatter.title}`}
            >
              <link rel="alternate" href={post.frontmatter.alternative} hrefLang={post.frontmatter.alternativeLang}/>
            </Helmet>
            <div className="blog-post">
              <h1 className="major" style={{ marginBottom: '0.2em' }}>{post.frontmatter.title}</h1>
              <h2 className="blog-heading">
                <span>{t('Posted By')}</span>&nbsp;
                <FontAwesomeIcon icon={faUser}/> <span>{post.frontmatter.author}</span>&nbsp;
                <FontAwesomeIcon icon={faCalendar}/> <span>{formatBlogDate(post.frontmatter.date, post.frontmatter.lang)}</span>&nbsp;
                <FontAwesomeIcon icon={faComment}/> <DisqusCommentCount shortname="mijnpresta" prefix={post.frontmatter.path} identifier={post.frontmatter.disqus}/>&nbsp;
                <FontAwesomeIcon icon={faTag}/> <span>{post.frontmatter.tags.map(tag => <Link key={tag} to={`/${i18n.language}/tags/${_.kebabCase(tag)}/`}>{tag} </Link>)}</span>&nbsp;
                <div style={{ cursor: 'pointer', float: 'right', marginTop: '5px', marginBottom: '5px', height: '42px' }}>
                  <div style={{ display: 'block' }}>
                    <div style={{ display: 'inline-block' }}>
                      <FacebookShareButton
                        url={sharePath}
                      >
                        <FacebookIcon size={32}/>
                      </FacebookShareButton>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <TwitterShareButton
                        url={sharePath}
                        title={post.frontmatter.title}
                      >
                        <TwitterIcon size={32}/>
                      </TwitterShareButton>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <LinkedinShareButton
                        url={sharePath}
                        title={post.frontmatter.title}
                      >
                        <LinkedinIcon size={32}/>
                      </LinkedinShareButton>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <GooglePlusShareButton
                        url={sharePath}
                      >
                        <GooglePlusIcon size={32}/>
                      </GooglePlusShareButton>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <PinterestShareButton
                        url={sharePath}
                        media={post.frontmatter.scene}
                      >
                        <PinterestIcon size={32}/>
                      </PinterestShareButton>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <WhatsappShareButton
                        url={sharePath}
                        title={post.frontmatter.title}
                      >
                        <WhatsappIcon size={32}/>
                      </WhatsappShareButton>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <TelegramShareButton
                        url={sharePath}
                        title={post.frontmatter.title}
                      >
                        <TelegramIcon size={32}/>
                      </TelegramShareButton>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <RedditShareButton
                        url={sharePath}
                        title={post.frontmatter.title}
                      >
                        <RedditIcon size={32}/>
                      </RedditShareButton>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      <EmailShareButton
                        url={sharePath}
                      >
                        <EmailIcon size={32}/>
                      </EmailShareButton>
                    </div>
                  </div>
                </div>
              </h2>
              <span className="image fit">
            <img src={post.frontmatter.scene} alt=""/>
          </span>
              <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: post.html }}/>
            </div>
          </div>
          <div style={{ clear: 'both' }}/>
          <div>
            <h2 className="major">{t('Comments')}</h2>
            <div style={{
              backgroundColor: 'white',
              padding: '1em',
              borderRadius: '0',
            }}>
              <Disqus shortname="mijnpresta" identifier={post.frontmatter.disqus}/>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
);
}

export default translate()(Template);
