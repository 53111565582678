import React, { Component } from 'react';
import { Link } from 'gatsby';
import { translate, TranslationFunction } from 'react-i18next';

import { i18n } from 'i18next';

const logoWit = require('../../images/mijnprestalogobalk_wit.svg');

interface IProps {
  t?: TranslationFunction,
  i18n?: i18n,
}

class Header extends Component<IProps> {
  render() {
    const { t, i18n } = this.props;
    return (
      <header id="header">
        <Link to={`/${i18n.language}/`} className="title">
          <img src={logoWit} style={{ height: '80px', width: 'auto' }} alt=""/>
        </Link>
        <nav>
          <ul>
            <li><Link to={`/${i18n.language}/`}>{t('Home')}</Link></li>
            <li><Link to={`/${i18n.language}/blog/`} className="active">{t('Blog')}</Link></li>
          </ul>
        </nav>
      </header>
    )
  };
}

export default translate()(Header);
